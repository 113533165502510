$mobileNavbarHeight: 3rem;

$toolbarInnerHeight: 2.75rem;
$toolbarBorderHeight: .125rem;
$toolbarRealPaddingY: .25rem;

$toolbarPaddingTop: $toolbarRealPaddingY;
$toolbarHeightWithoutPaddingBottom: $toolbarInnerHeight + $toolbarBorderHeight + $toolbarPaddingTop;
// For Apple home bar, if env inset bottom is less than expected value, we keep a bottom padding,
// otherwise we don't keep the bottom padding.
$toolbarPaddingBottom: max(env(safe-area-inset-bottom), $toolbarRealPaddingY);

$mobilePaddingTop: $mobileNavbarHeight;
$mobilePaddingBottom: calc($toolbarHeightWithoutPaddingBottom + $toolbarPaddingBottom);

$lineHeight: 2rem;
$paddingTop: calc($mobileNavbarHeight / 2 - $lineHeight / 2);

#mobile-navbar {
  height: $mobileNavbarHeight;
  padding-top: $paddingTop;
  padding-bottom: $paddingTop;
  line-height: $lineHeight;
}

#mobile-navbar-currency-picker {
  height: $mobileNavbarHeight;
  padding-top: $paddingTop;
  padding-bottom: $paddingTop;
  line-height: 0;
}

#main-wrapper {
  padding-top: $mobilePaddingTop;
  padding-bottom: $mobilePaddingBottom;

  @media (min-width: 640px) and (hover: hover) and (pointer: fine) {
    padding-top: 0;
    padding-bottom: 0;
    min-height: calc(100vh - env(safe-area-inset-bottom)); // watch out for iPad home bar
  }
}

#desktop-tabs-bar, #desktop-tabs-bar-placeholder {
  height: 3.125rem;
}

#desktop-tabs-bar.fixed-top {
  position: fixed;
}

#main {
  @apply flex-grow w-screen desktop:max-w-screen-lg py-5;
}


#mobile-toolbar {
  .mobile-toolbar-button {
    height: calc($toolbarHeightWithoutPaddingBottom + $toolbarPaddingBottom);
    border-top-width: $toolbarBorderHeight;
    padding-top: $toolbarPaddingTop;
    padding-bottom: $toolbarPaddingBottom;
  }
}

#create-transaction-buttons {
  @apply fixed z-10;
  right: 1rem;
  bottom: calc(env(safe-area-inset-bottom) + $mobilePaddingBottom + 1rem);

  @media (min-width: 640px) and (hover: hover) and (pointer: fine) {
    bottom: calc(env(safe-area-inset-bottom) + 1rem);
  }
}

#create-transaction-button-bg {
  @apply absolute;
  @apply aspect-square rounded-full bg-gray-50 shadow-md;
  @apply transition-all duration-150 ease-in-out;

  width: 0;
  // height required those who don't support aspect-ratio
  height: 0;
  right: -1rem;
  bottom: -1rem;
}

.create-transaction-button {
  // min-h-sm required those who don't support aspect-ratio
  @apply absolute fcc w-12 h-12 aspect-square shadow-md bg-app-bg rounded-full;
  @apply text-white cursor-pointer hover:bg-primary-600 duration-150 ease-in-out;

  transition-property: right, bottom, transform;
  right: 0;
  bottom: 0;
}

$span: 50ms;

#create-transaction-button-expense {
  transition-delay: 0ms;
}

#create-transaction-button-income {
  transition-delay: $span*1;
}

#create-transaction-button-transfer {
  transition-delay: $span*2;
}

#create-transaction-button-correction {
  transition-delay: $span*3;
}

#create-transaction-buttons.active {
  #create-transaction-button-bg {
    right: -10rem;
    bottom: -10rem;
    width: 20rem;
    // height required those who don't support aspect-ratio
    height: 20rem;
  }

  .create-transaction-button {
    @apply shadow-none;
  }

  $r1: 6rem;
  $b1: -.5rem;
  $r2: 4.75rem;
  $b2: 2.5rem;

  #create-transaction-button-expense {
    right: $r1;
    bottom: $b1;
  }

  #create-transaction-button-income {
    right: $r2;
    bottom: $b2;
  }

  #create-transaction-button-transfer {
    right: $b2;
    bottom: $r2;
  }

  #create-transaction-button-correction {
    right: $b1;
    bottom: $r1;
  }

  #create-transaction-button-add, #create-transaction-button-add-hover {
    @apply rotate-180;
  }
}

