@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@layer base {
  html {
    @apply bg-app-bg;
    // https://benborgers.com/posts/webkit-tap-highlight-color
    -webkit-tap-highlight-color: transparent;

    font-family: 'Roboto', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
}

@layer components {
  .fcc {
    @apply flex items-center justify-center;
  }

  .inline-fcc {
    @apply inline-flex items-center justify-center;
  }

  .fcb {
    @apply flex items-center justify-between;
  }

  .inline-fcb {
    @apply inline-flex items-center justify-between;
  }
}

@layer utilities {
  .min-h-xs {
    min-height: 1.5rem;
  }

  .min-h-sm {
    min-height: 1.75rem;
  }

  .min-h-base {
    min-height: 2rem;
  }

  .min-h-md {
    min-height: 2rem;
  }

  .min-h-lg {
    min-height: 2.25rem;
  }

  .text-responsive-xs {
    @apply text-xs sm:text-xs;
  }

  .text-responsive-sm {
    @apply text-xs sm:text-sm;
  }

  .text-responsive {
    @apply text-sm sm:text-base;
  }

  .text-responsive-lg {
    @apply text-base sm:text-lg;
  }
}
