$paddingY: 1rem;
$safariURLBarHeight: 6rem;

.modal-wrap {
  padding: $paddingY;
}

.modal {
  max-height: calc(100vh - 2 * $paddingY - $safariURLBarHeight);
  overflow: scroll;
}
